import React, { memo, useCallback, useEffect, useState } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Dashboard } from 'Components/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { firstCompanyIdSelector, fetchingStProjects } from 'Containers/Projects/selectors';
import { CompanyProjectsDataModal } from 'Containers/Projects/Modals';
import {
  activeProjectSelector,
  dashboardProjectListSelector,
  fetchingDashboardProjectListSelector,
} from 'Containers/Dashboard/selector';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { listMyProjectsForDashboard, listCompanyWipProjectsForDashboard } from 'Containers/Projects/actions';

import { setActiveProject } from 'Containers/Dashboard/actions';
import { UserModel } from 'Containers/User/Models/UserModel';
import { useUser } from 'Context/User';
import { getCoordinatesFromAddress } from 'Utils/google';

import { useProjectsEvents } from 'Hooks/useProjectsEvents';
import getSTJobs from 'Utils/serviceTitanJobs';

const defaultCenter = {
  lat: 56.130366,
  lng: -106.346771,
};

function DashboardContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { onlyShowMyProjects } = useSelector(userFeatureFlagsSelector, areEqual);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const userData: UserModel = useUser();
  // TODO:: should use different selectors to avoid repeat api calls on tab switch

  // local variables
  const myProjects: CompanyProjectsDataModal = useSelector(dashboardProjectListSelector, areEqual);
  const fetching = useSelector(fetchingDashboardProjectListSelector, areEqual);
  const activeProject = useSelector(activeProjectSelector, areEqual);

  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(1);
  const [tab, setTab] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [lat, setLat] = useState(56.130366);
  const [lng, setLng] = useState(-106.346771);

  const StJobsLoading = useSelector(fetchingStProjects);

  // project events hook
  const [refreshProjects, setRefreshProjects] = useProjectsEvents();

  const handleListProjects = useCallback(() => {
    switch (tab) {
      case 0: {
        dispatch(listMyProjectsForDashboard(userData.id));
        break;
      }
      case 1: {
        dispatch(listCompanyWipProjectsForDashboard(firstCompanyId));
        break;
      }
      default: {
        break;
      }
    }
  }, [firstCompanyId, userData, tab]);

  useEffect(() => {
    if (firstCompanyId) {
      handleListProjects();
    }
  }, [tab, firstCompanyId]);

  const updateCenter = useCallback(() => {
    if (isFirstLoad) {
      setZoom(16);
    } else {
      setIsFirstLoad(true);
      setZoom(10);
    }
  }, [isFirstLoad]);

  const centerDefault = useCallback(() => {
    if (myProjects?.data?.length) {
      const [firstProject] = myProjects.data;
      const { address } = firstProject;
      getCoordinatesFromAddress(address, setLat, setLng);
      dispatch(setActiveProject(firstProject));
    }
  }, [myProjects]);

  useEffect(() => {
    if (lat !== 0 && lng !== 0) {
      setCenter({
        lat,
        lng,
      });
    }
  }, [lat, lng]);

  useEffect(() => {
    if (activeProject?.id) {
      const { address } = activeProject;
      getCoordinatesFromAddress(address, setLat, setLng);
      updateCenter();
    }
  }, [activeProject]);

  useEffect(() => {
    if (!activeProject?.id) {
      centerDefault();
    }
  }, [myProjects]);

  const handleNavigateToProject = useCallback((id: number) => {
    history.push(`/projects/${id}/rocketscan`);
  }, []);

  const handleViewAll = useCallback(() => history.push('/projects'), []);

  const setTabWIP = useCallback(() => setTab(1), []);
  const setTabMyProjects = useCallback(() => setTab(0), []);

  const handleChangeProject = useCallback(
    (project: any) =>
      activeProject?.id === project.id ? handleNavigateToProject(project.id) : dispatch(setActiveProject(project)),
    [activeProject]
  );

  // refresh project list on push events
  useEffect(() => {
    if (refreshProjects) {
      handleListProjects();
      setRefreshProjects(false);
    }
  }, [refreshProjects]);

  useEffect(() => {
    if (userData.st_technician_id && userData.st_tenant_id && !StJobsLoading) {
      getSTJobs(userData, dispatch);
    }
  }, [userData.st_technician_id, userData.st_tenant_id, StJobsLoading]);

  return (
    <Dashboard
      handleViewAll={handleViewAll}
      activeTab={tab}
      setTabWIP={setTabWIP}
      setTabMyProjects={setTabMyProjects}
      zoom={zoom}
      center={center}
      fetching={fetching}
      myProjects={myProjects}
      handleChangeProject={handleChangeProject}
      activeProject={activeProject}
      onlyShowMyProjects={onlyShowMyProjects}
    />
  );
}

const DashboardContainerMemo = memo(DashboardContainer, areEqual);

export { DashboardContainerMemo as DashboardContainer };
